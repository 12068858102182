import React from 'react'
import Img from 'gatsby-image'

export default function Banner({ frontmatter, disableSource, style }) {
  return (
    <div
      style={{
        display: `flex`,
        flexDirection: `column`,
        marginBottom: `1.55rem`,
        marginLeft: `-1.25rem`,
        marginRight: `-1.25rem`,
        ...style,
      }}
    >
      <Img fluid={frontmatter.banner.childImageSharp.fluid} />
      {!disableSource && frontmatter.bannerCredit && (
        <div style={{ textAlign: `center`, fontSize: `0.7rem` }}>
          Source:
          {' '}
          <a href={frontmatter.bannerCredit} alt="Image credits">
            {frontmatter.bannerCredit}
          </a>
        </div>
      )}
    </div>
  )
}
