import React from 'react'
import { css } from '@emotion/core'

export default function ReadingTime({ readingTimeInMinutes, updatedDate }) {
  return (
    <p
      css={css`
        font-size: 0.7rem;
        font-style: italic;
      `}
    >
      {parseInt(readingTimeInMinutes, 0)} min read, last updated on {updatedDate}
    </p>
  )
}
